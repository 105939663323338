import banner from '@/assets/images/home/banner.png'
import title from '@/assets/images/home/title.svg'
import number from '@/assets/images/home/number.svg'
import redButton from '@/assets/images/home/redButton.svg'
import versionBg from '@/assets/images/home/version-bg.svg'
import freedom from '@/assets/images/home/freedom.png'
import equality from '@/assets/images/home/equality.png'
import unity from '@/assets/images/home/unity.png'
import peace from '@/assets/images/home/peace.png'
import unique01 from '@/assets/images/home/01.png'
import unique02 from '@/assets/images/home/02.png'
import unique03 from '@/assets/images/home/03.png'
import unique04 from '@/assets/images/home/04.png'
import unique04_mobile from '@/assets/images/home/04_mobile.png'

import number01 from '@/assets/images/home/01.svg'
import number02 from '@/assets/images/home/02.svg'
import number03 from '@/assets/images/home/03.svg'
import number04 from '@/assets/images/home/04.svg'

import manifest from '@/assets/images/home/manifesto.png'
import manifest_mobile from '@/assets/images/home/manifestto_mobile.png'

import closeIcon from '@/assets/images/home/close.svg'
import openIcon from '@/assets/images/home/open.svg'
import homeAudio from '@/assets/audio/home.mp4'
import { useState } from 'react'

import subjectMobile from '@/assets/images/home/subjectMobile.svg'
import versionBgMobile from '@/assets/images/home/versionbg_mobile.png'

type RoadItem = {
  title: string,
  contentList: string[],
  open: boolean
}

const roadList: RoadItem[] = [
  {
    title: 'Phase 1: Community Building',
    contentList: [
      '• Establish initial community',
      '• Unite core members',
      '• Form governance structure',
      '• Launch Origin Pass initiative'
    ],
    open: false
  }, {
    title: 'Phase 2: DAO Governance',
    contentList: [
      '• Implement decentralized governance',
      '• Establish proposal mechanism',
      '• Perfect voting system',
      '• Set community rules'
    ],
    open: false
  }, {
    title: 'Phase 3: Economic Framework',
    contentList: [
      '• Issue community token',
      '• Build incentive mechanism',
      '• Establish value system',
      '• Initialize economic cycle'
    ],
    open: false
  }, {
    title: 'Phase 4: Physical Integration',
    contentList: [
      '• Explore offline connections',
      '• Establish physical spaces',
      '• Launch offline events',
      '• Expand real-world influence'
    ],
    open: false
  }, {
    title: 'Phase 5: Growth Verification',
    contentList: [
      '• Conduct on-chain census',
      '• Track development metrics',
      '• Optimize governance',
      '• Adjust growth strategy'
    ],
    open: false
  }, {
    title: 'Phase 6: Sovereign Community',
    contentList: [
      '• Gain broader recognition',
      '• Expand community influence',
      '• Explore sovereignty model',
      '• Achieve long-term vision'
    ],
    open: false
  }
]

const RoadmapItem = ({ title, contentList, open, handleOpen }: { title: string, contentList: string[], open: boolean, handleOpen: () => void }) => {

  return <div className='px-[28px] py-[29px] border-[4px] rounded-[16px] w-[629px] mt-[15px] border-[black] bg-[white]'>
    <div className='text-[24px] flex items-center justify-between'>
      <p>{title}</p>
      <img src={open ? closeIcon : openIcon} alt="" onClick={() => {
        handleOpen()
      }} />
    </div>
    {
      open && <div className='border-t border-[black] mt-[23px] pt-[15px]'>
        {
          contentList.map((content: string, index: number) => (
            <p className='text-[15px]' key={index}>{content}</p>
          ))
        }
      </div>
    }
  </div>
}

const RoadmapItemMobile = ({ title, contentList, open, handleOpen }: { title: string, contentList: string[], open: boolean, handleOpen: () => void }) => {

  return <div className='px-[18px] py-[19px] border-[4px] rounded-[16px] w-full mt-[15px] border-[black] bg-[white]'>
    <div className='text-[16px] flex items-center justify-between'>
      <p>{title}</p>
      <img src={open ? closeIcon : openIcon} alt="" onClick={() => {
        handleOpen()
      }} />
    </div>
    {
      open && <div className='border-t border-[black] mt-[23px] pt-[15px]'>
        {
          contentList.map((content: string, index: number) => (
            <p className='text-[15px]' key={index}>{content}</p>
          ))
        }
      </div>
    }
  </div>
}


export const Home = () => {
  const [activeRoad, setActiveRoad] = useState<number>(0)

  return (
    <>
      <div className='block md:hidden w-full'>
        <div className='text-[#fff]'>
          <img className='block mt-10px mx-[auto]' src={subjectMobile} alt="" />
        </div>
        <div className='w-full bg-[#68C07D] py-[9px] mt-[20px]'>
          <p className='text-[black] text-center text-[16px] mt-[10px]'>Where Freedom Knows No Boundaries</p>
          <img className='mt-[10px] mx-[auto] w-[196px] h-[36px]' src={number} alt="" />
          <p className='text-center'>Numbers</p>
        </div>
        <img src={banner} className='w-full' alt="" />
        <div className='border-t-[4px] overflow-hidden border-b-[4px] border-[#000] h-[84px] bg-[#2EE86C] flex items-center text-[28px] flex justify-between gap-[26px] px-[26px]'>
          <span className='whitespace-nowrap'>Buy $Home Now</span>
          <span className='whitespace-nowrap'>Buy $Home Now</span>
        </div>
        <div className='py-[25px] bg-[#68C07D] px-[30px]'>
          <div className='h-[251px] w-[331px] grow bg-[#000] rounded-[23px]'>
            <video src={homeAudio} autoPlay muted loop className='w-full h-full'>
              Your browser does not support the video tag.
            </video>
          </div>
          <p className='mt-[25px] text-[59px]'>DID-404</p>
          <p>Peace, dignity and equality on a healthy planet</p>
          <p className='mt-[25px] text-[12px]'>ERC-404 is an experimental, mixed ERC20 / ERC721 implementation with native liquidity and fractionalization for semi-fungible tokens.</p>
          <div className='relative mt-[53px] flex items-center justify-center'>
            <img className='w-[292px] h-[60px]' src={redButton} alt="" />
            <p className='w-[292px] h-full absolute flex items-center top-[0px] left-[0px] justify-center text-[20px]'>Coming soon</p>
          </div>
        </div>
        <div className='pt-[10px] bg-[#68C07D] relative px-[23px] pb-[20px]'>
          <img className='w-full mx-[auto]' src={versionBgMobile} />
          <div className='absolute top-[105px] px-[60px] w-full left-[0px]'>
            <p className='text-center text-[12px]'>Our Version</p>
            <div className='text-[12px] mt-[26px]'>
              <p className='text-center'> In a world divided by borders,</p>
              <p className='text-center'>HOME is building a digital civilization that transcends boundaries.</p>

              <p className='text-center mt-[20px]'>We believe the next decade of the internet</p>
              <p className='text-center'> is not just about connecting information,</p>
              <p className='text-center'> but about connecting identity and belonging.</p>

              <p className='text-center mt-[20px]'>Through blockchain technology,</p>
              <p className='text-center'>we're redefining the concept of "nation",</p>
              <p className='text-center'>creating a digital realm where no passport is needed,</p>
              <p className='text-center'>where everyone finds true belonging.</p>

              <p className='text-center mt-[20px]'>This is not just a technological revolution,</p>
              <p className='text-center'>but an evolution of civilization.</p>
            </div>
            <div className='relative mt-[20px] mx-[auto] flex items-center justify-center'>
              <img className='w-[292px] h-[60px]' src={redButton} alt="" />
              <p className='w-full h-full absolute flex items-center top-[0px] left-[0px] justify-center text-[20px]'>Join The Community</p>
            </div>
          </div>
          <div className='mt-[116px]'>
            <p className='text-[52px]'>Our Values</p>
            <div className='flex flex-col justify-between items-center gap-[23px] mt-[52px]'>
              <div className='w-[268px]'>
                <img src={freedom} alt="" />
                <div className='text-[24px] mt-[14px] pl-[16px]'>
                  <p className='text-[24px] text-center'>Freedom</p>
                  <ul className='text-[12px]  text-center'>
                    <li>• Breaking geographical boundaries</li>
                    <li>• Eliminating identity barriers</li>
                    <li>• Enabling free flow of value</li>
                    <li>• Protecting individual choice</li>
                  </ul>
                </div>
              </div>
              <div className='w-[268px]'>
                <img src={equality} alt="" />
                <div className='text-[24px] mt-[14px] pl-[16px]'>
                  <p className='text-[24px]  text-center'>Equality</p>
                  <ul className='text-[12px]  text-center'>
                    <li>• Voice for everyone</li>
                    <li>• Transparent and fair rules</li>
                    <li>• Equal opportunities</li>
                    <li>• Equal decision-making rights</li>
                  </ul>
                </div>
              </div>
              <div className='w-[268px]'>
                <img src={unity} alt="" />
                <div className='text-[24px] mt-[14px] pl-[16px]'>
                  <p className='text-[24px] text-center'>Unity</p>
                  <ul className='text-[12px] text-center'>
                    <li>• Building home together</li>
                    <li>• Collective wisdom</li>
                    <li>• Mutual trust and benefit</li>
                    <li>• Shared development</li>
                  </ul>
                </div>
              </div>
              <div className='w-[268px]'>
                <img src={peace} alt="" />
                <div className='text-[24px] mt-[14px] pl-[16px]'>
                  <p className='text-[24px] text-center'>Peace</p>
                  <ul className='text-[12px] text-center'>
                    <li>• Harmonious community</li>
                    <li>• Rational dialogue</li>
                    <li>• Consensus priority</li>
                    <li>• Embracing diversity</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='mt-[26px]'>
            <p className='text-[48px] text-left'>$HOME Unique Value</p>
            <div className='flex flex-col gap-[20px] justify-center items-center mt-[28px]'>
              <div className='w-full relative'>
                <img src={unique01} alt="" />
                <div className='w-full h-full absolute left-[0] top-[0] px-[33px] py-[33px]'>
                  <img src={number01} alt="" className='w-[46px] h-[48px]' />
                  <p className='text-[24px] whitespace-nowrap mt-[16px]'>Permanent Identity</p>
                  <p className='text-[12px] mt-[16px]'>Lifetime honorary credential of HOME
                    Testament of genesis citizens</p>
                </div>
              </div>
              <div className='w-full relative'>
                <img src={unique02} alt="" />
                <div className='w-full h-full absolute left-[0] top-[0] px-[33px] py-[33px]'>
                  <img src={number02} alt="" className='w-[46px] h-[48px]' />
                  <p className='text-[24px] whitespace-nowrap mt-[16px]'>Community Governance</p>
                  <p className='text-[12px] mt-[16px]'>Community Governance
                    Participate in all major decisions
                    Proposal and voting rights
                    Shape HOME's future</p>
                </div>
              </div>
              <div className='w-full relative'>
                <img src={unique03} alt="" />
                <div className='w-full h-full absolute left-[0] top-[0] px-[33px] py-[33px]'>
                  <img src={number03} alt="" className='w-[46px] h-[48px]' />
                  <p className='text-[24px] whitespace-nowrap mt-[16px]'>Exclusive Rights</p>
                  <p className='text-[12px] mt-[16px]'>Priority access to HOME events
                    Unique ecosystem benefits
                    Eligibility for rare airdrops</p>
                </div>
              </div>
              <div className='w-full relative'>
                <img src={unique04_mobile} alt="" />
                <div className='w-full h-full absolute left-[0] top-[0] px-[33px] py-[33px]'>
                  <img src={number04} alt="" className='w-[46px] h-[48px]' />
                  <p className='text-[24px] whitespace-nowrap mt-[5px]'>Identity Privileges</p>
                  <p className='text-[12px] mt-[10px]'> Exclusive community badge Unique social identifier Genesis community access</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='relative mt-[10px]'>
          <img className='w-full auto' src={manifest_mobile} alt="" />
          <div className='absolute w-full px-[23px] left-[50%] top-[53px] translate-x-[-50%]'>
            <p className='text-[48px] leading-none'>HOME Manifesto HOME</p>
            <p className='text-[12px] mt-[5px] pr-[23px]'>
              You are not just early adopters,
              but pioneers of a digital civilization,
              founders of a borderless homeland,
              and creators of a new world.
            </p>
            <p className='text-[12px] mt-[5px] pr-[23px]'>
              The Origin Pass will forever commemorate
              your courage to participate in history,
              your vision to witness the evolution of human civilization,
              and your belief in a free future.
            </p>
          </div>
        </div>
        <div className='bg-[#68C07D] px-[22px] py-[55px]'>
          <p className='text-[52px]'>Roadmap</p>
          <div>
            {
              roadList.map((item: RoadItem, index: number) => {

                return <RoadmapItemMobile key={index} title={item.title} contentList={item.contentList} open={activeRoad === (index + 1)} handleOpen={() => setActiveRoad(activeRoad === (index + 1) ? 0 : (index + 1))} />
              })
            }
          </div>
        </div>
        <div className='border-t-[4px] overflow-hidden border-b-[4px] border-[#000] h-[84px] bg-[#2EE86C] flex items-center text-[28px] flex justify-between gap-[26px] px-[26px]'>
          <span className='whitespace-nowrap'>Buy $Home Now</span>
          <span className='whitespace-nowrap'>Buy $Home Now</span>
        </div>
        <div className='bg-[#68C07D]'>
          <p className='text-[40px] pt-[38px] px-[19px]'>Join The $HOME</p>
          <div className='px-[19px] mt-[10px] text-[20px]'>
            <p>X. @homesandgardens</p>
            <p>TG. @homesandgardens</p>
          </div>
        </div>
        <div className='py-[17px] px-[16px] text-[#fff] mt-[16px]'>
          <p>@2024 Home,all Rights Reserved</p>
          <div className='flex gap-[40px]'>
            <span>Terms & Conditions</span>
            <span> Privacy Policy</span>
          </div>
        </div>
      </div>


      <div className='hidden md:block w-full max-w-[1280px] mx-[auto]'>
        <div className='relative'>
          <img src={banner} className='w-full' alt="" />
          <div className='absolute w-full h-auto left-[50%] top-[20%] translate-x-[-50%] translate-y-[-20%]'>
            <img src={title} className='mt-[60px] mx-[auto]' />
            <p className='text-[white] text-center text-[20px] mt-[10px]'>Where Freedom Knows No Boundaries</p>
            <img className='mt-[18px] mx-[auto] w-[196px] h-[36px]' src={number} alt="" />
          </div>
        </div>
        <div className='border-t-[4px] border-b-[4px] border-[#000] h-[84px] bg-[#2EE86C] flex items-center text-[28px] flex justify-between gap-[26px] px-[26px]'>
          <span>Buy $Home Now</span>
          <span>Buy $Home Now</span>
          <span>Buy $Home Now</span>
          <span>Buy $Home Now</span>
          <span>Buy $Home Now</span>
        </div>
        <div className='bg-[#68C07D] px-[68px] py-[78px]'>
          <div className='flex mt-[85px] gap-[160px]'>
            <div className='h-[428px] w-[484px] grow bg-[#000] rounded-[23px]'>
              <video src={homeAudio} autoPlay muted loop className='w-full h-full'>
                Your browser does not support the video tag.
              </video>
            </div>
            <div className='grow'>
              <p className='text-[64px]'>DID-404</p>
              <p className='text-[20px]'>
                Peace, dignity and equalityon a healthy planet
              </p>
              <p className='w-[465px] mt-[20px]'>ERC-404 is an experimental, mixed ERC20 / ERC721 implementation with native liquidity and fractionalization for semi-fungible tokens.</p>
              <div className='relative mt-[73px]'>
                <img className='w-[292px] h-[60px]' src={redButton} alt="" />
                <p className='w-[292px] h-full absolute flex items-center top-[0px] left-[0px] justify-center text-[20px]'>Coming soon</p>
              </div>
            </div>

          </div>
          <div className='mt-[110px] relative'>
            <img className='w-[1145px] h-[828px] min-w-[1145px] min-h-[828px] mx-[auto]' src={versionBg} />
            <div className='absolute top-[330px] h-[486px] w-full left-[0px]'>
              <p className='text-center text-[32px]'>Our Version</p>
              <p className='w-[450px] text-center mt-[42px] mx-[auto]'>
                In a world divided by borders,
              </p>
              <p className='w-[500px] text-center mx-[auto]'>
                HOME is building a digital civilization that transcends boundaries.
              </p>
              <p className='w-[350px] text-center mt-[10px] mx-[auto]'>
                We believe the next decade of the internet
                is not just about connecting information,
              </p>
              <p className='w-[350px] text-center mx-[auto]'>
                but about connecting identity and belonging.
              </p>
              <p className='w-[360px] text-center mt-[10px] mx-[auto]'>
                Through blockchain technology,
              </p>
              <p className='w-[360px] text-center  mx-[auto]'>
                we're redefining the concept of "nation",
              </p>
              <p className='w-[400px] text-center  mx-[auto]'>
                creating a digital realm where no passport is needed,
              </p>
              <p className='w-[360px] text-center  mx-[auto]'>
                where everyone finds true belonging.
              </p>
              <p className='w-[300px] text-center mt-[10px] mx-[auto]'>
                This is not just a technological revolution,
                but an evolution of civilization.
              </p>
              <div className='relative mt-[20px] mx-[auto] flex items-center justify-center'>
                <img className='w-[292px] h-[60px]' src={redButton} alt="" />
                <p className='w-full h-full absolute flex items-center top-[0px] left-[0px] justify-center text-[20px]'>Join The Community</p>
              </div>
            </div>
            <div className='mt-[116px]'>
              <p className='text-[52px]'>Our Values</p>
              <div className='flex justify-between items-center gap-[23px] mt-[52px]'>
                <div className='w-[268px]'>
                  <img src={freedom} alt="" />
                  <div className='text-[24px] mt-[34px] pl-[16px]'>
                    <p className='text-[24px]'>Freedom</p>
                    <ul className='text-[12px]'>
                      <li>• Breaking geographical boundaries</li>
                      <li>• Eliminating identity barriers</li>
                      <li>• Enabling free flow of value</li>
                      <li>• Protecting individual choice</li>
                    </ul>
                  </div>
                </div>
                <div className='w-[268px]'>
                  <img src={equality} alt="" />
                  <div className='text-[24px] mt-[34px] pl-[16px]'>
                    <p className='text-[24px]'>Equality</p>
                    <ul className='text-[12px]'>
                      <li>• Voice for everyone</li>
                      <li>• Transparent and fair rules</li>
                      <li>• Equal opportunities</li>
                      <li>• Equal decision-making rights</li>
                    </ul>
                  </div>
                </div>
                <div className='w-[268px]'>
                  <img src={unity} alt="" />
                  <div className='text-[24px] mt-[34px] pl-[16px]'>
                    <p className='text-[24px]'>Unity</p>
                    <ul className='text-[12px]'>
                      <li>• Building home together</li>
                      <li>• Collective wisdom</li>
                      <li>• Mutual trust and benefit</li>
                      <li>• Shared development</li>
                    </ul>
                  </div>
                </div>
                <div className='w-[268px]'>
                  <img src={peace} alt="" />
                  <div className='text-[24px] mt-[34px] pl-[16px]'>
                    <p className='text-[24px]'>Peace</p>
                    <ul className='text-[12px]'>
                      <li>• Harmonious community</li>
                      <li>• Rational dialogue</li>
                      <li>• Consensus priority</li>
                      <li>• Embracing diversity</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>


            <div className='mt-[116px]'>
              <p className='text-[52px] text-center'>$ HOME Unique Value</p>
              <div className='flex justify-center items-center gap-[12px] mt-[108px]'>
                <div className='w-[374px] relative'>
                  <img src={unique01} alt="" />
                  <div className='w-full h-full absolute left-[0] top-[0] px-[48px] py-[37px]'>
                    <img src={number01} alt="" className='w-[46px] h-[48px]' />
                    <p className='text-[24px] whitespace-nowrap mt-[16px]'>Permanent Identity</p>
                    <p className='text-[12px] mt-[16px]'>Lifetime honorary credential of HOME
                      Testament of genesis citizens</p>
                  </div>
                </div>
                <div className='w-[374px] relative'>
                  <img src={unique02} alt="" />
                  <div className='w-full h-full absolute left-[0] top-[0] px-[48px] py-[37px]'>
                    <img src={number02} alt="" className='w-[46px] h-[48px]' />
                    <p className='text-[24px] whitespace-nowrap mt-[16px]'>Community Governance</p>
                    <p className='text-[12px] mt-[16px]'>Community Governance
                      Participate in all major decisions
                      Proposal and voting rights
                      Shape HOME's future</p>
                  </div>
                </div>
                <div className='w-[374px] relative'>
                  <img src={unique03} alt="" />
                  <div className='w-full h-full absolute left-[0] top-[0] px-[48px] py-[37px]'>
                    <img src={number03} alt="" className='w-[46px] h-[48px]' />
                    <p className='text-[24px] whitespace-nowrap mt-[16px]'>Exclusive Rights</p>
                    <p className='text-[12px] mt-[16px]'>Priority access to HOME events
                      Unique ecosystem benefits
                      Eligibility for rare airdrops</p>
                  </div>
                </div>
              </div>
              <div className='w-full relative'>
                <img src={unique04} alt="" />
                <div className='w-full h-full absolute left-[0] top-[0] px-[48px] py-[37px]'>
                  <img src={number04} alt="" className='w-[46px] h-[48px]' />
                  <p className='text-[24px] whitespace-nowrap mt-[16px]'>Identity Privileges</p>
                  <p className='text-[12px] mt-[16px] w-[158px]'>
                    Exclusive community badge Unique social identifier Genesis community access</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='relative'>
          <img className='w-[1280px] h-[720px]' src={manifest} alt="" />
          <div className='absolute w-full px-[68px] left-[50%] top-[117px] translate-x-[-50%]'>
            <p className='text-[52px]'>HOME Manifesto HOME</p>
            <p className='text-[20px] mt-[82px] w-[345px]'>
              You are not just early adopters,
              but pioneers of a digital civilization,
              founders of a borderless homeland,
              and creators of a new world.
            </p>
            <p className='text-[20px] mt-[20px] w-[445px]'>
              The Origin Pass will forever commemorate
              your courage to participate in history,
              your vision to witness the evolution of human civilization,
              and your belief in a free future.
            </p>
          </div>
        </div>
        <div className='bg-[#68C07D] px-[68px] py-[78px] flex justify-between item-start'>
          <span className='text-[52px]'>Roadmap</span>
          <div>
            {
              roadList.map((item: RoadItem, index: number) => {

                return <RoadmapItem key={index} title={item.title} contentList={item.contentList} open={activeRoad === (index + 1)} handleOpen={() => setActiveRoad(activeRoad === (index + 1) ? 0 : (index + 1))} />
              })
            }
          </div>
        </div>
        <div className='border-t-[4px] border-b-[4px] border-[#000] h-[84px] bg-[#2EE86C] flex items-center text-[28px] flex justify-between gap-[26px] px-[26px]'>
          <span>Buy $Home Now</span>
          <span>Buy $Home Now</span>
          <span>Buy $Home Now</span>
          <span>Buy $Home Now</span>
          <span>Buy $Home Now</span>
        </div>
        <div className='bg-[#68C07D] px-[68px] py-[78px] flex justify-between item-start'>
          <div>
            <p className='text-[20px] mt-[28px]'>About</p>
            <p className='text-[20px] mt-[28px]'>Origin Pass</p>
            <p className='text-[20px] mt-[28px]'>Road Map</p>
          </div>
          <div>
            <p className='text-[52px]'>Join The $HOME</p>
            <div className='flex items-center justify-end mt-[53px] text-[20px]'>
              <span>X. @homesandgardens</span>
              <span>TG. @homesandgardens</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}