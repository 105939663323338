import { Outlet } from "react-router-dom"
import { Header } from "./Header"
import { useEffect } from "react"

export const Layout = () => {
  useEffect(() => {
    window.solana?.on?.('connect', () => {
      console.log('Wallet connected:', window?.solana?.publicKey?.toString());
    });

    window?.solana?.on?.('disconnect', () => {
      console.log('Wallet disconnected');
    });
  }, [])

  return (
    <div className="w-[100vw] h-[100vh] overflow-hidden flex flex-col font-[aalaojiezhaopaiti] md:min-w-[1280px]">
      <Header />
      <div className="w-full grow overflow-hidden overflow-y-auto bg-[#000]">
        <Outlet />
      </div>
    </div>
  )
}