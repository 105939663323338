import logo from '@/assets/images/header/logo.svg'
import tg from '@/assets/images/header/tg.svg'
import x from '@/assets/images/header/x.svg'
import wallet from '@/assets/images/header/wallet.svg'
import { encryptionAddress } from '@/utils'
import { useAtomValue } from 'jotai'
import { userAddressAtom } from '@/store'
import { useConnectWallet } from '@/hooks/useConnectWallet'
import menu from '@/assets/images/header/menu.svg'
import { useState } from 'react'

export const Header = () => {
  const userAddress = useAtomValue(userAddressAtom)
  const { handleConnect } = useConnectWallet()
  const [showMobileMenu, setShowMenu] = useState<boolean>(false)

  return <>
    <div className="hidden md:flex h-[68px] min-h-[68px] items-center px-[94px] bg-[#000] text-[white] justify-between">
      <img src={logo} className='w-[126px] h-auto' />
      <div className='flex items-center justify-between gap-[53px]'>
        <div className='flex items-center justify-center gap-[40px] font-[AaLaoJieZhaoPaiTi]'>
          <span>About</span>
          <span>Origin Pass</span>
          <span>Roadmap</span>
          <span>White paper</span>
        </div>
        <div className='flex items-center gap-[16px]'>
          <img className='w-[32px] cursor-pointer' src={x} alt="" onClick={() => window.open('https://x.com/homedao_?s=21', '__blank')} />
          <img className='w-[32px] cursor-pointer' src={tg} alt="" onClick={() => window.open('https://t.me/HomeDAOofficial', '__blank')} />
          {!userAddress ? <div className='bg-[#FF8000]  w-[120px] h-[33px] items-center justify-center flex gap-[10px] rounded-[4px] cursor-pointer'>
            <img src={wallet} alt="" />
            <span className='text-[#000]' onClick={() => handleConnect()}>Connect</span>
          </div> : <p>{encryptionAddress(userAddress)}</p>}
        </div>
      </div>
    </div>
    <div className="flex md:hidden h-[100px] min-h-[68px] items-center px-[25px] py-[25px] bg-[#000] text-[white] justify-between">
      <img src={logo} className='w-[105px] h-auto' />
      <img src={menu} className='w-[24px] h-auto' onClick={() => setShowMenu(true)} />
    </div>
    <>{
      showMobileMenu && <div className='fixed w-full h-full left-[0px] top-[0px] bg-[#000]'>
        <p className='text-[white] mt-[68px] text-center text-[24px]' onClick={() => setShowMenu(false)}>About</p>
        <p className='text-[white] mt-[68px] text-center text-[24px]' onClick={() => setShowMenu(false)}>Origin Pass</p>
        <p className='text-[white] mt-[68px] text-center text-[24px]' onClick={() => setShowMenu(false)}>Roadmap</p>
        <p className='text-[white] mt-[68px] text-center text-[24px]' onClick={() => setShowMenu(false)}>White Paper</p>
        <div className='border-t mt-[68px] flex justify-center py-[22px]'>
          <img src={logo} className='w-[200px] h-auto' />
        </div>
      </div>
    }</>
  </>
}