import { userAddressAtom } from "@/store";
import { useSetAtom } from "jotai";
import { useCallback } from "react"

export const useConnectWallet = () => {
  const setUserAddress = useSetAtom(userAddressAtom)

  const handleConnect = useCallback(async () => {
    if (window.solana && window.solana.isPhantom) {
      try {
        const resp = await window.solana.connect(); // 请求连接钱包
       
        setUserAddress(resp.publicKey.toString())
      } catch (err) {
        console.error('Wallet connection failed:', err);
      }
    }
  }, [])

  return { handleConnect }
}